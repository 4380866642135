import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/galleries.js";

import image from "assets/img/bg2.jpg";
import myescapeImage from "assets/img/faces/my-escape-cover.jpg";
import 'assets/css/fa.css';
import "assets/scss/material-kit-react.scss";
import SEO from "../components/seo";
import ImageGallery from 'react-image-gallery';
import { Paper } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
const useStyles = makeStyles(styles);
export default function Galleries(props) {
  const [photos, setPhotos] = React.useState([]);
  const classes = useStyles();
  const { ...rest } = props;
  console.log(props);
  const galleries = props.data.allWordpressWpPhotosEvents.nodes;
  return (
    <div>
      <Header
        color="transparent"
        brand="Duaa Rasho"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <SEO description={"This book was born from personal experience. For a woman who made the decision to migrate alone. Experiences have been documented in this book. "} image={myescapeImage} pathname="/myescape/"></SEO>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>

          <Paper elevation="5" className={classes.paper}>
            {photos.length < 1 ? galleries.map(gallery => {
              return (
                <Card className={classes.card} onClick={() => 
                  setPhotos(gallery.acf.photos.map(photo => { 
                    return {
                      original:photo.full_image_url.source_url,
                      thumbnail:photo.thumbnail_image_url,
                      originalClass: 'fullWidth'
                    } 
                  }))}>
                  <CardActionArea >

                  <CardContent style={{display:'flex', justifyContent:"space-between"}}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {gallery.title} 
                    </Typography>
                    <PhotoAlbumIcon color="primary" style={{ fontSize: 40 }}></PhotoAlbumIcon>
                  </CardContent>
                  <CardMedia
                    className={classes.media}
                    image={gallery.acf.photos[0].full_image_url.source_url}
                    title={gallery.title}
                  />
                </CardActionArea>
              </Card> )
        }): <>
              <ImageGallery showBullets showThumbnails={false} items={photos} />
              <Button onClick={() => setPhotos([])} color="primary" style={{width:"100%",margin:'0 0 0 0'}}>Back to galleries overview</Button>
            </>}
          </Paper>
          <Footer whiteFont />
      </div>
    </div>
    </div >
  );
}
export const postQuery = graphql`
query AllGalleries {
  allWordpressWpPhotosEvents {
    nodes {
      title
      acf {
        photos {
          full_image_url {
            source_url
          }
          target
          thumbnail_image_url
          title
          url
        }
      }
    }
  }
}
`